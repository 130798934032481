import * as _reactDom2 from "react-dom";

var _reactDom = "default" in _reactDom2 ? _reactDom2.default : _reactDom2;

var exports = {};
var m = _reactDom;
{
  var i = m.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;

  exports.createRoot = function (c, o) {
    i.usingClientEntryPoint = true;

    try {
      return m.createRoot(c, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };

  exports.hydrateRoot = function (c, h, o) {
    i.usingClientEntryPoint = true;

    try {
      return m.hydrateRoot(c, h, o);
    } finally {
      i.usingClientEntryPoint = false;
    }
  };
}
export default exports;
export const createRoot = exports.createRoot,
      hydrateRoot = exports.hydrateRoot;